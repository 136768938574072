@tailwind base;
@tailwind components;
@tailwind utilities;


::-moz-selection { 
  color: #fff;
  background: #FF8531;
}

::selection {
  color: #fff;
  background: #FF8531;
}
body {
  background-color: #F1F3F9;
}

ul>li.active:hover {
  color: white;
  background: #FF8531;
}

select.session_sel option:hover {
  background-color: orange;
}
textarea{
  resize: none;
}
/* .scrollbar-none {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  /* scrollbar-width: none; */
  /* for Firefox */

/* Loader styles */

.circle {
  display: inline-block;
  background-color: #FF8531;
  height: 15px;
  width: 15px;
  border-radius: 25px;
}


#ball-1 {
  animation: bounce 1s infinite;
}

#ball-2 {
  animation: bounce 1.1s infinite;

}

.custom-tooltip {
font-size:12px;


}

#ball-3 {
  animation: bounce 1.2s infinite;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(23px);
  }

  60% {
    transform: translateY(-25px);
  }

  80% {
    transform: translateY(0);
  }
}

canvas 
{
height:auto  !important;
width:500px  !important;


}
#pie-chart {
  height:376px !important;
  width:auto !important
}
#polar-chart {
  height:376px !important;
  width:auto !important
}
.chart-container {
  width: 500px; /* Set the desired width */
  height: auto; /* Set the desired height */
}



@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
button.bg-theme-color {
  border: none;
  cursor: pointer;
  color: white;
  padding: .5rem 1.25rem;
  background: #FF8531;
  
	display:inline-block;
  position: relative;
  overflow: hidden;
}

button.bg-theme-color:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .2);
  opacity: 0;
  border-radius: 50%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
  /* background-color :#9c9c9c; */
}
button.bg-theme-color:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
.custom-dot {
  width: 30px;
  height: 3px;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.slick-dots li{
  width: 30px !important;
}
.slick-prev, .slick-next{
  display: none !important;
}

.custom-dot.active {
  background-color: #FF8531; /* Change the active dot color */
}





